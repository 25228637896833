import React from 'react';
import './Leistungen.css';
import { Button, Card, Accordion, Alert, Image } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { IoIosAdd, IoIosRemove } from 'react-icons/io'

import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

// phone assets
import whatsappAsset from '../../assets/whatsapp_black_24dp.svg';
import phoneAsset from '../../assets/phone_black_24dp.svg';

class Leistungen extends React.Component {  

  constructor(props) {
    super(props);

    this.state = {
      accordionDefaultActive: 0,
      accordionActives: {},
      leistungen: []
    }

    this.CustomToggle = this.CustomToggle.bind(this);
  }

  componentDidMount() {
    import('./data/leistungen.json').then(
      resp => {
        const accordionActives = {};
        resp.default.map(r => accordionActives[r.id] = this.state.accordionDefaultActive);
        this.setState({accordionActives: accordionActives});
        this.setState({leistungen: resp.default})
      }
    );
  }

  CustomToggle({ children, eventKey, ...props }) {
    const decoratedOnClick = useAccordionToggle(eventKey, (e) => {
      if( eventKey === this.state.accordionActives[props.accordion] )
        this.setState({accordionActives: {...this.state.accordionActives, [props.accordion]: null}});
      else
      this.setState({accordionActives: {...this.state.accordionActives, [props.accordion]: eventKey}});
    });
  
    return (
      <Card.Header onClick={decoratedOnClick} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px'}}>
        {children}
      </Card.Header>
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.titlePrefix} Leistungen</title>
        </Helmet>
      <Card id="Leistungen-content">
        <Card.Header as="h1" className="h4">
          Leistungen & Preise
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Alert variant='info' >Aus rechtlichen Gründen darf ich keine <strong>Diabetiker</strong> behandeln. Bitte wenden Sie sich an einen <strong>Podologen</strong>!</Alert>
            {this.state.leistungen.map( leistungsGruppe => (
              <Accordion key={leistungsGruppe.id} defaultActiveKey={this.state.accordionDefaultActive} style={{marginBottom: '50px'}}><b><u>{leistungsGruppe.id}:</u></b>
                {leistungsGruppe.leistungen.map( leistung => (
                  <Card key={leistung.id}>
                    <this.CustomToggle eventKey={leistung.id} accordion={leistungsGruppe.id}>
                      <Button variant="secondary" className="leistungToggle">{this.state.accordionActives[leistungsGruppe.id] === leistung.id ? <IoIosRemove /> : <IoIosAdd />}</Button>{' '}<span style={{fontWeight: 'bold', flex: '1 1 auto', fontSize: 'smaller'}}>{leistung.title}{leistung.duration !== null ? ' (ca. ' + leistung.duration + ' Minuten)' : ''}</span>{' '}<span style={{fontWeight: 'bold', fontSize: 'smaller'}}>{leistung.price} €</span>
                    </this.CustomToggle>
                    <Accordion.Collapse eventKey={leistung.id}>
                      <Card.Body>{ReactHtmlParser(leistung.description)}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            ))}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted"><div style={{marginRight: '20px', fontFamily: 'monospace', float: 'left'}}><Image src={phoneAsset} style={{ verticalAlign: 'top' }} alt="Telefonsymbol" /> 07309 / 819 063 1<br/><Image src={whatsappAsset} style={{ verticalAlign: 'top' }} alt="WhatsApp-Symbol" /> 01573 / 034 570 1</div><div style={{float: 'left', fontWeight: '300'}}>{' - '}<span style={{marginLeft: '10px'}}>Termine nur nach Vereinbarung!</span></div></Card.Footer>
      </Card>
      </div>
    );
  }

}

export {Leistungen};
