import React from 'react';
import './Kontakt.css';
import { Card, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// phone assets
import whatsappAsset from '../../assets/whatsapp_black_24dp.svg';
import phoneAsset from '../../assets/phone_black_24dp.svg';

class Kontakt extends React.Component {  

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.titlePrefix} Kontakt</title>
        </Helmet>
      <Card id="Kontakt-content">
        <Card.Header as="h1" className="h4">
          Kontakt
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <strong>Fußpflege Roelofs</strong><br/>
            <p>
              Schleifweg 1<br/>
              89264 Weißenhorn<br/>
              OT Wallenhausen
            </p>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted"><div style={{marginRight: '20px', fontFamily: 'monospace', float: 'left'}}><Image src={phoneAsset} style={{ verticalAlign: 'top' }} alt="Telefonsymbol" /> 07309 / 819 063 1<br/><Image src={whatsappAsset} style={{ verticalAlign: 'top' }} alt="WhatsApp-Symbol" /> 01573 / 034 570 1</div><div style={{float: 'left', fontWeight: '300'}}>{' - '}<span style={{marginLeft: '10px'}}>Termine nur nach Vereinbarung!</span></div></Card.Footer>
      </Card>
      </div>
    );
  }
  
}

export {Kontakt};
