import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

// bootstrap
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { Container, Jumbotron, Navbar, Image, Nav } from 'react-bootstrap';

// site components for router
import { Home } from './sites/Home'
import { About } from './sites/About'
import { Leistungen } from './sites/Leistungen';
import { Kontakt } from './sites/Kontakt';
import { Impressum, Datenschutz } from './metaMenu';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {navExpanded: false};

    this.logoClickHandler = this.logoClickHandler.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.toggleRef = React.createRef();
  }

  logoClickHandler() {
    if( this.state.navExpanded )
      this.toggleNavbar()
  }

  toggleNavbar(e) {
    if( getComputedStyle(this.toggleRef.current).getPropertyValue("display") !== 'none' ) {
      
      this.setState({ navExpanded: !this.state.navExpanded });

    }
  }

  render() {
    return (
      <Container id="mainContainer" className="p-3" fluid="xl">
        <Navbar expanded={this.state.navExpanded} expand="md" variant="light" style={{backgroundColor: 'rgba(63,104,138,.9)'}}>
          <Navbar.Brand>
            <Link onClick={this.logoClickHandler} to="/">
              <Image src={logo} width="50" height="auto" alt="Fußpflege Roelofs" />
            </Link>{' '}
            <span id="mainNavBrandSlogan" style={{fontWeight: 'bolder', fontStyle: 'italic', cursor: 'default'}}>Fußpflege Roelofs</span>
          </Navbar.Brand>

          <Navbar.Toggle ref={this.toggleRef} onClick={this.toggleNavbar} aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav onClick={this.toggleNavbar} className="ml-auto">
                <IndexLinkContainer to="/">
                  <Nav.Item as="a" className="nav-link">
                    Startseite
                  </Nav.Item>
                </IndexLinkContainer>
                
                <LinkContainer to="/about">
                  <Nav.Item as="a" className="nav-link">
                    Über mich...
                  </Nav.Item>
                </LinkContainer>

                <LinkContainer to="/leistungen">
                  <Nav.Item as="a" className="nav-link">
                    Leistungen
                  </Nav.Item>
                </LinkContainer>  

                <LinkContainer to="/kontakt">
                  <Nav.Item as="a" className="nav-link">
                    Kontakt
                  </Nav.Item>
                </LinkContainer>              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Jumbotron id="mainContainerJumbotron" className="pb-1">
          

          <Switch>
            <Route exact path={["/", "/home"]} render={(props) => <Home {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
            <Route path="/about" render={(props) => <About {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
            <Route path="/leistungen" render={(props) => <Leistungen {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
            <Route path="/kontakt" render={(props) => <Kontakt {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
            <Route path="/impressum" render={(props) => <Impressum {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
            <Route path="/datenschutz" render={(props) => <Datenschutz {...props} titlePrefix={`Fußpflege Roelofs:`} />} />
          </Switch>

          <div className="spacer"></div>
          <hr style={{margin: '1rem 0'}} />
          <p id="mainFooter">
            <span id="mainFooterCopyright">© 2020 by ritter - IT&mehr.</span>
            <span className="spacer"></span>
            <span id="mainFooterMenu">
              <Nav id="mainFooterMenuNav">
                <LinkContainer to="/impressum"><Nav.Item as="a" className="nav-link">Impressum</Nav.Item></LinkContainer><span class="divider">|</span>
                <LinkContainer to="/datenschutz"><Nav.Item as="a" className="nav-link">Datenschutz</Nav.Item></LinkContainer>
              </Nav>
            </span>
          </p>
        </Jumbotron>
      </Container>
    );
  }
}

export default App;
