import React from 'react';
import './About.css';
import { Card, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// portrait
import portrait from './portrait.jpg';

// phone assets
import whatsappAsset from '../../assets/whatsapp_black_24dp.svg';
import phoneAsset from '../../assets/phone_black_24dp.svg';


class About extends React.Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.titlePrefix} Über mich ...</title>
        </Helmet>
      <Card id="About-content">
        <Card.Header as="h1" className="h4">
          Über mich ...
        </Card.Header>
        <Card.Body>
          <Card.Text as="div">
            <Image src={portrait} roundedCircle style={{width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block', marginBottom: '25px'}} />
            <div>
              <p>Mein Name ist  <b>Hildegard Roelofs</b>,</p>
              <p>ich habe bereits 1983 eine Ausbildung zur <b>examinierten Krankenschwester</b> abgeschlossen. 2009 habe ich mich entschieden die Ausbildung zur <b>ärztlich geprüften Fußpflegerin</b> zu machen.</p>
              <p>Zunächst war ich für drei Jahre in einer Fußpflegepraxis angestellt, bevor ich mich dazu entschied eine eigene Praxis aufzubauen.</p>
              <p>Ich behandle meine Kunden mit sehr viel Freude, Engagement und Mitgefühl. Durch meinen Beruf als Krankenschwester hab ich ein ausgeprägtes <b>Hygienebewusstsein</b>. Darauf lege ich besonders viel Wert!</p>
            </div>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted"><div style={{marginRight: '20px', fontFamily: 'monospace', float: 'left'}}><Image src={phoneAsset} style={{ verticalAlign: 'top' }} alt="Telefonsymbol" /> 07309 / 819 063 1<br/><Image src={whatsappAsset} style={{ verticalAlign: 'top' }} alt="WhatsApp-Symbol" /> 01573 / 034 570 1</div><div style={{float: 'left', fontWeight: '300'}}>{' - '}<span style={{marginLeft: '10px'}}>Termine nur nach Vereinbarung!</span></div></Card.Footer>
      </Card>
      </div>
    );
  }

}

export {About};
