import React from 'react';
import './Home.css';
import { Card, CardDeck, Button, Container, Row, Col, Image } from 'react-bootstrap';

import { Link } from 'react-router-dom';

// bilder: praxis
import praxis1 from './praxis-1.jpg';
import praxis2 from './praxis-2.jpg';
import praxis3 from './praxis-3.jpg';

// bilder: produkte
import produkt1 from './produkt-1.jpg';
import produkt2 from './produkt-2.jpg';
import produkt3 from './produkt-3.jpg';
import produkt4 from './produkt-4.jpg';
import { Helmet } from 'react-helmet';

class Home extends React.Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.titlePrefix} Startseite</title>
        </Helmet>
      <h1 className="h4" style={{textDecoration: 'underline', textDecorationColor: '#555', textAlign: 'center', marginBottom: '2rem'}}>Herzlich Willkommen bei Fußpflege Roelofs!</h1>
      <CardDeck>
        <Card>
          <Card.Header as="h4">
            Über mich ...
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Mein Name ist <b>Hildegard Roelofs</b>. Ich bin <i>examinierte Krankenschwester</i> und <i>ärztlich geprüfte Fußpflegerin</i>. Weitere Infos zu meiner Person finden Sie hier: <Link to="/about"><Button className={'link-button'}>[mehr lesen]</Button></Link>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h4">
            Leistungen & Preise
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Alle Leistungen und die dazugehörigen Preise finden Sie auf dieser Seite: <Link to="/leistungen"><Button className={'link-button'}>[mehr lesen]</Button></Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
      <CardDeck style={{marginTop: '10px'}}>
        <Card>
          <Card.Header as="h4">
            Praxis
          </Card.Header>
          <Card.Body>
            <Container>
              <Row xs={1} sm={2}>
                <Col><Image src={praxis1} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
                <Col><Image src={praxis2} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
                <Col><Image src={praxis3} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h4">
            Produkte
          </Card.Header>
          <Card.Body>
            <Container>
              <Row xs={1} sm={2}>
                <Col><Image src={produkt1} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
                <Col><Image src={produkt4} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
                <Col><Image src={produkt2} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
                <Col><Image src={produkt3} style={{maxWidth: '100%', marginBottom: '10px'}} /></Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </CardDeck>
      </div>
    );
  }
  
}

export {Home};
